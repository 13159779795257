.Goldman {
  font-family: Goldman;
}
.Nunito {
  font-family: Nunito Sans;
}
.box {
  width: 13%;
}
.grid-container {
  display: grid;
  column-gap: 20px;
  row-gap: 10px;
  grid-template-columns: repeat(7, 1fr);
}
.banner {
  height: 100vh;
}
.item {
  border-radius: 9px;
}
.name {
  font-size: 64px;
}
.activity-parent {
  height: 20rem;
  /* background-color: green; */
  width: 90%;
}
.activity {
  height: 85%;
  width: 100%;
  top: 20%;
  background-color: #f2f2f2;
  /* background-color:red; */
  border-radius: 6px;
  position: absolute;
}
.applyButton {
  width: 250px;
  height: 75px;
  border: none;
  border-radius: 50px;
  font-size: 32px;
  font-weight: bolder;
  animation-name: buttonAnimation;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
/* .upButton{
  position: fixed;
  z-index: 5;
  top: 85%;
  left: 90%;
  cursor: pointer;
  border-radius: 50%;
  background-color: white;
} */
.downButton {
  animation-name: buttonDown;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
#upArrowButton {
  animation-name: buttonUp;
  animation-duration: 3s;
  top: 80%;
  animation-iteration-count: infinite;
}

.scrollDown {
  animation-name: example;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.scrollDownMobile{
  animation-name: mobile;
  animation-duration: 2s;

  animation-iteration-count: infinite;
}
@keyframes mobile {
  0% {
    top: 79%;
  }
  25% {
    top: 83%;
  }
  50% {
    top: 79%;
  }
  75% {
    top: 83%;
  }
  100% {
    top: 79%;
  }
}
@keyframes buttonDown {
  0% {
    left: 92%;
  }
  25% {
    left: 91%;
  }
  50% {
    left: 92%;
  }
  75% {
    left: 91%;
  }
  100% {
    left: 92%;
  }
}
@keyframes buttonUp {
  0% {
    top: 85%;
  }
  25% {
    top: 83%;
  }
  50% {
    top: 85%;
  }
  75% {
    top: 83%;
  }
  100% {
    top: 85%;
  }
}
@keyframes buttonAnimation {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1.1, 1.1);
  }
  34% {
    transform: scale(1, 1);
  }
}
@keyframes example {
  0% {
    top: 0px;
  }
  25% {
    top: 30px;
  }
  35% {
    top: 0px;
  }
}
/* .downButton{
  position: fixed;
  z-index: 5;
  top: 85%;
  left: 95%;
  cursor: pointer;
  border-radius: 50%;
  background-color: white;
} */

@media (min-width: 100px) {
  .grid-container {
    display: grid;
    column-gap: 20px;
    row-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }
  .activity-parent {
    height: 35rem;
    /* background-color: green; */
  }

  .banner {
    width: 100%;
    height: 80vh;
  }
  .downButton {
    position: fixed;
    z-index: 5;
    top: 85%;
    left: 90%;
    cursor: pointer;
    border-radius: 50%;
    background-color: white;
  }
  
}
@media (min-width: 375px) {
  .grid-container {
    display: grid;
    column-gap: 20px;
    row-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }
  .activity-parent {
    height: 35rem;
    /* background-color: green; */
  }

  .banner {
    height: 80vh;
  }
  .downButton {
    position: fixed;
    z-index: 5;
    top: 85%;
    left: 80%;
    cursor: pointer;
    border-radius: 50%;
    background-color: white;
  }
}

@media (min-width: 768px) {
  .banner {
    height: 100vh;
  }
  .name {
    font-size: 54px;
  }
  .grid-container {
    display: grid;
    column-gap: 20px;
    row-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
  }
  .activity-parent {
    height: 20rem;
    /* background-color: green; */
  }
  .activity {
    height: 100%;
    width: 80%;
    top: 0;
    right: 0;
    background-color: #f2f2f2;
    /* background-color:red; */
    border-radius: 6px;
    position: absolute;
  }
  .downButton {
    position: fixed;
    z-index: 5;
    top: 85%;
    left: 90%;
    cursor: pointer;
    border-radius: 50%;
    background-color: white;
  }
  .scrollDownMobile{
    visibility: hidden;
  }
}
@media (min-width: 900px) {
  .grid-container {
    display: grid;
    column-gap: 20px;
    row-gap: 10px;
    grid-template-columns: repeat(5, 1fr);
  }
  .downButton {
    position: fixed;
    z-index: 5;
    top: 85%;
    left: 93%;
    cursor: pointer;
    border-radius: 50%;
    background-color: white;
  }
}

@media (min-width: 1300px) {
  .grid-container {
    display: grid;
    column-gap: 10px;
    row-gap: 10px;
    grid-template-columns: repeat(7, 1fr);
  }
}

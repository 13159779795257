.jobCard {
  transition: all 0.3s ease-out;
}
.transition {
  transition: all 0.9s ease-in-out;
}
.jobCard:hover {
  box-shadow: 0.2px 3px 3px 0.2px;
  transition: all 0s ease-out;
}
/* .events {
  -webkit-transform: translate3d(0,0,0) !important;
  transform: translate3d(0,0,0) !important;
} */
/* .events:hover {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
} */
@keyframes smooth-appear {
  to {
    bottom: 0px;
    opacity: 1;
  }
}

.jobBoxAnimation {
  bottom: -100%;
  opacity: 0;
  left: 0%;
  transform: translate3d(0,0,0) !important;
  -webkit-transform: translate3d(0,0,0) !important;
  /* transform: translateX(-50%); */
  animation: smooth-appear 800ms ease forwards;
}
.applyBoxAnimation {
  bottom: -100%;
  opacity: 0;
  left: 26%;
  transform: translateX(-50%);
  animation: smooth-appear 800ms ease forwards;
}
.info_details {
  font-family: "Rubik";
}
::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@font-face {
  font-family: myFirstFont;
  src: url("https://fonts.googleapis.com/css?family=Tangerine:bold,bolditalic|Inconsolata:italic|Droid+Sans");
}
.logoName {
  text-decoration: none;
  font-family: "Righteous";
}
.logoName:hover {
  text-decoration: none;
}
.logoName:active {
  text-decoration: none;
}
.logoName:link {
  text-decoration: none;
}
.sidebar-nav > .nav-link:hover {
  background-color: #f0f0f0; /* Change this to the desired hover color */
}
.dropdown-toggle::after {
  display: none !important; 
}
.btn:focus, .btn:active {
  outline: none !important;
  box-shadow: none !important;
}
.react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before {
    content: "\25B2";
    position: absolute;
    top: 5%;
    left: 45%;
    margin: auto;
    color: black;
  }
  
  .react-datepicker__year-dropdown::after {
    content: "\25BC";
    display: block;
    position: relative;
    bottom: 15%;
    color: black;
  }
  .date {
    color: black !important;
  }

.picker {
width: "100%";
outline: none;
border:none ;

}
.cross:hover{
    color: red;
}
.responsive-text {
    font-size: 18px; /* Default font size */
  }
  .custom-switch{
    cursor: pointer;
  }
  .custom-control-label::before {
    background-color: #28a745;
}

.custom-switch .custom-control-label::after {
    background-color: white;
}

.custom-control-input:checked~.custom-control-label::before {
    background-color: red;
}
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .responsive-text {
      font-size: 14px;
    }
  }
  
  /* Media query for even smaller screens */
  @media (max-width: 480px) {
    .responsive-text {
      font-size: 10px;
    }
  }